<template>
<div>
  <div style="margin: 20px 0px"><span>选择收货地址:</span></div>
  <div class="person-look-address-border">
            <div class="person-look-address-border-center">

            <el-card shadow="hover" class="box-card" :style="selectAddressId===index? addressSelectedStyle : {}" @click.native="checkThisAddress( index, item )" v-for="( item, index ) in addressList" :key="index">
              <div slot="header" class="clearfix">
                <span style="font-size: 14px">收货人：{{item.fullname}}</span>
                <el-button v-if="item.is_default=='y'" style="float: right; padding: 3px 0" type="text">默认地址</el-button>
              </div>
<!--              控制显示行数-->
              <div class="text item" style="font-size: 12px;line-height: 15px;text-overflow:-o-ellipsis-lastline;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 4;-webkit-box-orient: vertical;">
                <p>手机号：{{item.mobile_format}}</p>
                <br>
                <el-tooltip class="item" effect="dark" :content="checkedAddressName( item.region_id ) + item.address" placement="bottom-start">
                 <span> 收货地址：{{ checkedAddressName( item.region_id ) }} {{item.address}}</span>
                </el-tooltip>
              </div>
            </el-card>


            <div class="person-look-address-border2">
              <div class="person-look-address-border2-center">
                <div class="person-look-address-border2-center-border" @click="dialogFormVisible=true">
                  <i class="iconfont icon-a-addto wh-16 b"></i>
                  <div class="mb-10"></div>
                  <span class="f-12 h-57">添加收货地址</span>
                </div>
              </div>
            </div>

            </div>
            <!-- 弹出框 -->
          <el-dialog
            title="收货地址信息"
            :visible.sync="dialogFormVisible"
            width="35%"
            :close-on-click-modal="false"
            :destroy-on-close="true"
            center
            :before-close="addressDialogClose"
          >
            <el-form
              :model="form"
              ref="addressForm"
              style="text-align: center"
              :rules="rules"
            >
              <el-form-item
                label="地址信息："
                :label-width="formLabelWidth"
                style="display: inline-block"
                prop="region_id"
              >
                <el-input v-show="false" v-model="form.region_id"></el-input>
                <Region
                  :regionStyle="regionStyle"
                  ref="RegionVisble"
                  @reginCallBack="reginBack"
                ></Region>
              </el-form-item>

              <el-form-item
                label="详细信息："
                :label-width="formLabelWidth"
                style="display: inline-block"
                prop="address"
              >
                <el-input
                  v-model="form.address"
                  autocomplete="off"
                  style="width: 400px; font-size: 12px"
                  placeholder="请输入详细地址信息，如道路、门牌号、小区、楼栋号、单元等信息"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="邮政编码："
                :label-width="formLabelWidth"
                style="display: inline-block"
                prop="zip_code"
              >
                <el-input
                  v-model="form.zip_code"
                  autocomplete="off"
                  type="number"
                  style="width: 400px; font-size: 12px"
                  placeholder="请填写邮编"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="收货人姓名："
                :label-width="formLabelWidth"
                style="display: inline-block"
                prop="fullname"
              >
                <el-input
                  v-model="form.fullname"
                  autocomplete="off"
                  style="width: 400px; font-size: 12px"
                  placeholder="请填写收货人姓名"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="收货人电话："
                :label-width="formLabelWidth"
                style="display: inline-block"
                prop="mobile"
              >
                <el-input
                  v-model="form.mobile"
                  autocomplete="off"
                  style="width: 400px; font-size: 12px"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item style="display: block">
                <el-checkbox
                  v-model="form.is_default"
                  true-label="y"
                  false-label="n"
                  :checked="form.is_default == 'y'"
                  style="position: reative; left: -75px"
                  >设置为默认收获地址</el-checkbox
                >
              </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
              <button @click="submitAddress" style="width:200px;height:30px;font-size:18px;background:#00a2e6;position:reative;top:30px">确认</button>
            </div>
          </el-dialog>
  </div>
</div>
</template>

<script>
import {createUserAddress, getUserAddress, updateUserAddress} from "@/api/user";

export default {
name:"AddAddress",
data() {
    var checkMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请填写收获人电话"));
      }
      let reg = /^1([0-9])\d{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("手机号格式错误"));
      }
    };
    return {
      addressList: [],
      /* 个人抬头的单选框 */
      personRadio:1,
      /* 地址弹框 */
      dialogFormVisible:false,
      form: {
        /* 地址信息 */
        region_id: "",
        /* 详细地址信息 */
        address: "",
        /* 邮政编码 */
        zip_code: "",
        /* 收货人姓名 */
        fullname: "",
        /* 收货人电话 */
        mobile: "",
        // 是否默认地址
        is_default: "n",
      },
      //多选框状态
      rules: {
        /* 地址信息 */
        region_id: [{ required: true, message: "请选择地", trigger: "blur" }],
        /*详细地址信息  */
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        /* 邮政编码 */
        zip_code: [
          { required: true, message: "请输入邮政编码", trigger: "blur" },
        ],
        fullname: [
          { required: true, message: "请输入收货人姓名", trigger: "blur" },
        ],
        mobile: [{ validator: checkMobile, trigger: "blur" }],
      },
      /* label的宽度 */
      formLabelWidth: "115px",
      regionStyle: "width: 400px; font-size: 12px",
      value:"",

      selectAddressId: '',
      addressSelectedStyle: {
        border: '1px solid #00ace6',
        background: '#fff'
      }
    };
  },
  mounted() {
    this.loadAddress()
  },
  methods:{
    async loadAddress(){
      const res = await getUserAddress( this.$root.current_token )
      if( res.statusCode == 200 ){
        this.addressList = res.data
      }
    },
    async createAddress() {
      const res = await createUserAddress(this.form);
      if (res.statusCode == 200) {
        this.$notify({
          message: '添加成功',
          type: 'success'
        });
        this.loadAddress();
        this.form = {};
        this.dialogFormVisible = false
      } else {
        this.$notify({
          message: res.message,
          type: 'error'
        });
      }
    },
    async saveAddress() {
      const res = await updateUserAddress(this.form);
      if (res.statusCode == 200) {
        this.$notify({
          message: '修改成功',
          type: 'success'
        });
        this.loadAddress();
        this.form = {};
        this.dialogFormVisible = false
      } else {
        this.$notify({
          message: res.message,
          type: 'error'
        });
      }
    },
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    addressDialogClose() {
      this.$refs.RegionVisble.cleanVal();
      this.form = {};
      this.dialogFormVisible = false;
    },
    reginBack(region) {
      // region.forEach(element => {
      //   this.form.region_id += element + ','
      // });
      this.form.region_id = region[2];
    },
    submitAddress() {
      this.$refs.addressForm.validate((valid) => {
        if (valid) {
          if (!("id" in this.form)) {
            // 没有id添加
            this.createAddress();
          } else {
            this.saveAddress();
          }
          this.$refs.RegionVisble.cleanVal(); // 清理子组件数据
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    checkedAddressName(regionId) {
      var region = this.$regionJson.data;
      let address = '';
      region.forEach(province => {
        province.s.forEach(city => {
          city.s.forEach(country => {
            if (country.c == regionId) {
              address = province.n + ' ' + city.n + ' ' + country.n;
            }
          });
        });
      });

      return address;
    },
    checkThisAddress( index, item ){
      this.selectAddressId = index
      this.$emit( 'addressBack', item )
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-card__header{
  border-bottom: 1px solid #d9d9d9;;
}
/deep/.el-form-item{
  margin-bottom: 25px !important;
}
li{
  width: 75px;
  display: block;
  text-align: right;
  margin-bottom: 20px;
  font-size: 14px;
  color: #575757;
}
 .person-look-address-border{
        width: 860px;
        margin-left: 13px;
        .person-look-address-border-center{
          display: flex;
          width: 905px;
          flex-wrap: wrap;
          &>div{
            margin-bottom: 40px;
            width: 260px;
            background-color: #f7f7f7;
            margin-right: 40px;
            cursor: pointer;
          }
          .person-look-address-border1{
             background-color: #fff;
            border: 1px solid #00ace6;
          }
          .person-look-address-border1,
          .person-look-address-border2{
            height: 155px;
            .person-look-address-border1-center{
              width: 220px;
              margin: 0 auto;
              .person-look-address-border1-header{
                display: flex;
                justify-content: space-between;
                margin-top: 22px;
                height: 24px;
                border-bottom: 1px solid #b5b5b5;
              }
              .person-look-address-border1-bottom{
                p{
                  margin-top: 5px;
                  line-height: 20px;
                }
              }
            }
            .person-look-address-border2-center{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .person-look-address-border2-center-border{
                  width: 72px;
                  height: 40px;
                  text-align: center;
                  cursor: pointer;
                }
              }
          }
        }
}
</style>
